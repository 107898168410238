angular
    .module('annexaApp')
    .component('annexaPublishSitesInfo', {
        templateUrl: './components/common/annexa-publish-sites-info/annexa-publish-sites-info.html',
        controller: ['$filter', '$rootScope', 'NotificationFactory', 'ThirdFactory', 'GlobalDataFactory', 'CommonService', 'Language', '$state', function($filter, $rootScope, NotificationFactory, ThirdFactory, GlobalDataFactory, CommonService, Language, $state) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            
        }],
        bindings: {
        	publishSites: '='
        }
    })