angular
    .module('annexaApp')
    .component('annexaConfigGroupCustomField',{
        templateUrl: './components/common/annexa-config-group-custom-field/annexa-config-group-custom-field.html',
        controller:['$rootScope', 'RestService', 'CommonAdminService', 'Language', '$q', '$http', '$filter', 'CommonAdminModals', 'AnnexaFormlyFactory', 'ErrorFactory', 'HelperService', 'GlobalDataFactory', 'CustomFieldFactory', 'QueryFactory', 'CommonService', '$state', 
        function ($rootScope, RestService, CommonAdminService, Language, $q, $http, $filter, CommonAdminModals, AnnexaFormlyFactory, ErrorFactory, HelperService, GlobalDataFactory, CustomFieldFactory, QueryFactory, CommonService, $state) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.customFieldRequiredList = [
                { id: 'OPTIONAL', description: 'global.literals.OPTIONAL' },
                { id: 'REQUIRED', description: 'global.literals.REQUIRED' },
                { id: 'NO_EDITABLE', description: 'global.literals.NO_EDITABLE' }
            ];
			vm.customFieldProcedureType = [
                { id: 'DOSSIER', description: 'global.customFieldsTypes.DOSSIER' },
                { id: 'ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS' },
                { id: 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS_WITH_COPY' },
                { id: 'DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE' },
				{ id: 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' }
            ];
			vm.customFieldProcedureGuidedType = [
                { id: 'DOSSIER', description: 'global.customFieldsTypes.DOSSIER' },
                { id: 'ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS' },
                { id: 'DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' }
            ];
			vm.editGroupConfig = function(item, index){
            	var saveParentCustomFieldFunction = function(){
            		var self = this;
	                var data = this.annexaFormly.model.modal_body;
	                if(data && this.extra && this.extra.index != undefined && this.extra.customFieldParent){
	                	var modify = angular.copy(this.extra.customFieldParent);
	                	modify.requiredString = data.requiredString;
	                	modify.required = (modify.requiredString == 'REQUIRED');
	                	modify.requiredForEndDossier = (modify.requiredString == 'REQUIRED_TO_END');
	                	modify.hiddenField = (modify.requiredString == 'HIDDEN_FIELD');
	                	modify.noEditable = (modify.requiredString == 'NO_EDITABLE');
	    				modify.conditional = data.conditional;
	                	if(modify.conditional){
	                		modify.spel = data.spel;
	                		modify.spelDossier = data.spelDossier;
	                	}else{
	                		modify.spel = undefined;
	                		modify.spelDossier = undefined;
	                	}
	                	modify.canRepeat = data.canRepeat;
	                	modify.calculated = data.calculated;
	                	if(modify.calculated){
	                		modify.spelCalculated = data.spelCalculated;
	                		modify.spelCalculatedDossier = data.spelCalculatedDossier;
	                		modify.dynamicallyCalculated = data.dynamicallyCalculated;
	                	}else{
	                		modify.spelCalculated = undefined;
	                		modify.spelCalculatedDossier = undefined;
	                		modify.dynamicallyCalculated = false;
	                	}
	                	modify.customFieldType = data.customFieldType;
	                	modify.transactionTypes = [];
	                	if(data.transactionTypesAux){
	                		_.forEach(data.transactionTypesAux, function(tta){
	                			modify.transactionTypes.push({transactionType:{id:tta}});
	                		});
	                	}
	                	modify.descriptionLanguage1 = data.descriptionLanguage1;
	                	modify.descriptionLanguage2 = data.descriptionLanguage2;
	                	modify.descriptionLanguage3 = data.descriptionLanguage3;
	                	if(data.columnsQuery){
	                		modify.identifierField = data.columnsQuery.identifier;
	                		if(data.columnsQuery.labelProp && data.columnsQuery.labelProp.length > 0){
	                			var lbl = '';
	                			_.forEach(data.columnsQuery.labelProp, function(lp){
	                				lbl = lbl + ((lbl)?'--@--@--'+lp:lp);
	                			});
	                			modify.labelPropField = lbl;
	                		}else{
	                			modify.labelPropField = '';
	                		}
	                		modify.labelFieldsSeparator = data.columnsQuery.labelFieldsSeparator;
	                		modify.parentField = data.columnsQuery.parent;
	                	}
	                	var auxqp = [];
	                	if(modify.queryParams){
	                		auxqp = angular.copy(modify.queryParams);
	                	}
	                	modify.queryParams = [];
	                	if(data.queryParams){
	                		var keys = Object.keys(data.queryParams);
	                		_.forEach(keys, function(key){
	                			if(!key.endsWith('_Type_Param_Modal')){
	                            	var param = {};
	                            	if(auxqp){
	                            		param = $linq(auxqp).firstOrDefault(undefined, "x => x.parameter == '"+key+"'");
	                            		if(param){
	                            			param.parameterValue = data.queryParams[key];
	                            			param.parameterType = data.queryParams[key+"_Type_Param_Modal"];
	                            			modify.queryParams.push(param);
	                            		}else{
	                            			param = {parameter: key, parameterValue: data.queryParams[key], parameterType: data.queryParams[key+"_Type_Param_Modal"]};
	                            			modify.queryParams.push(param);
	                            		}
	                            	}else{
	                            		param = {parameter: key, parameterValue: data.queryParams[key], parameterType: data.queryParams[key+"_Type_Param_Modal"]};
	                            		modify.queryParams = [];
	                            		modify.queryParams.push(param);
	                            	}
	                			}else if(!data.queryParams[key.substring(0, key.indexOf('_Type_Param_Modal'))]){
	                				var param = {};
	                            	if(auxqp){
	                            		param = $linq(auxqp).firstOrDefault(undefined, "x => x.parameter == '"+key.substring(0, key.indexOf('_Type_Param_Modal'))+"'");
	                            		if(param){
	                            			param.parameterValue = undefined;
	                            			param.parameterType = data.queryParams[key];
	                            			modify.queryParams.push(param);
	                            		}else{
	                            			param = {parameter: key.substring(0, key.indexOf('_Type_Param_Modal')), parameterValue: undefined, parameterType: data.queryParams[key]};
	                            			modify.queryParams.push(param);
	                            		}
	                            	}else{
	                            		param = {parameter: key.substring(0, key.indexOf('_Type_Param_Modal')), parameterValue: undefined, parameterType: data.queryParams[key]};
	                            		modify.queryParams = [];
	                            		modify.queryParams.push(param);
	                            	}
	                			}
                            });
	                	}
	                	var auxrcf = [];
	                	if(modify.relatedCustomFields){
	                		auxrcf = angular.copy(modify.relatedCustomFields);
	                	}
	                	modify.relatedCustomFields = [];
	                	if(data.relatedCustomFields){
	                		var keys = Object.keys(data.relatedCustomFields);
                            _.forEach(keys, function(key){
                            	var param = {};
                            	if(auxrcf){
                            		param = $linq(auxrcf).firstOrDefault(undefined, "x => x.customFieldTemplateTag == '"+key+"'");
                            		if(param){
                            			param.customFieldValue = data.relatedCustomFields[key];
                            			modify.relatedCustomFields.push(param);
                            		}else{
                            			param = {customFieldTemplateTag: key, customFieldValue: data.relatedCustomFields[key]};
                            			modify.relatedCustomFields.push(param);
                            		}
                            	}else{
                            		param = {customFieldTemplateTag: key, customFieldValue: data.relatedCustomFields[key]};
                            		modify.relatedCustomFields = [];
                            		modify.relatedCustomFields.push(param);
                            	}
                            });
	                	}
	                	var cft = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValue'");
	            		if(cft && cft.data){
	            			modify.value = cft.data.model;
	            		}
	            		modify.groups = data.groups;
	            		vm.data.objectGroups[self.extra.index] = modify;
                		self.close();
	                }else{
	                	self.close();
	                }
            	}
            	if(vm.data.objectGroups && vm.data.objectGroups.length > index && vm.data.objectGroups[index].relatedCustomField && vm.data.objectGroups[index].relatedCustomField.id){
            		var parentValue = -1;
            		var cf = vm.data.objectGroups[index];
            		var promises = [];
            		var paraAux = ((cf.queryParams)?angular.copy(cf.queryParams):[]);
	            	var qp = {};
					_.forEach(paraAux, function(param){
						qp[param.parameter] = param.parameterValue;
						qp[param.parameter+"_Type_Param_Modal"] = param.parameterType;
					});
    	            promises.push(RestService.findOne('CustomField', cf.relatedCustomField.id));
    	            promises.push($http({method: 'GET',url: './api/custom_fields/is_disabled/'+cf.relatedCustomField.id}));
    	            promises.push($http({method: 'PUT',url: './api/custom_fields/listValues', data: {
    	            	customFieldId: cf.relatedCustomField.id,
    	            	parentValueId: parentValue,
    	            	additionalFilter: JSON.stringify(qp),
    	            	objectParentId:vm.data.parent,
    	            	objectParentType:vm.data.type,
    	            	inConfig:true
    	            }}));
    	            promises.push($http({method: 'GET',url: './api/custom_fields/getColumnsQuery/'+cf.relatedCustomField.id}));
    	            $q.all(promises).then(function(data){
    	            	var modal = angular.copy(CommonAdminModals.customFieldsGroupConfig);
	            		modal.alerts = [];
	            		modal.annexaFormly.model = {modal_body: angular.copy(cf)};
	            		if(modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.groups && modal.annexaFormly.model.modal_body.groups.length > 0){
							var groupsCopy = angular.copy(modal.annexaFormly.model.modal_body.groups);
							try{
								modal.annexaFormly.model.modal_body.groups = $linq(modal.annexaFormly.model.modal_body.groups).orderBy("x => x.viewOrder").toArray();
							}catch(e){
								modal.annexaFormly.model.modal_body.groups = groupsCopy;
							}
						}
						modal.annexaFormly.model.modal_body.relatedCustomField = JSOG.decode(data[0].data);
						if(modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.groups && modal.annexaFormly.model.modal_body.relatedCustomField.groups.length > 0){
							var groupsCFCopy = angular.copy(modal.annexaFormly.model.modal_body.relatedCustomField.groups);
							try{
								modal.annexaFormly.model.modal_body.relatedCustomField.groups = $linq(modal.annexaFormly.model.modal_body.relatedCustomField.groups).orderBy("x => x.viewOrder").toArray();
							}catch(e){
								modal.annexaFormly.model.modal_body.relatedCustomField.groups = groupsCFCopy;
							}
						}
						modal.annexaFormly.model.modal_body.canEditCustomField = JSOG.decode(data[1].data);
						modal.annexaFormly.model.modal_body.customfFieldParentTypeValue = vm.data.type;
						modal.annexaFormly.model.modal_body.showCustomfFieldParentTypeValue = vm.data.showCustomFieldType;
						modal.annexaFormly.model.modal_body.transactionTypesAux = [];
	                	if(modal.annexaFormly.model.modal_body.transactionTypes){
	                		_.forEach(modal.annexaFormly.model.modal_body.transactionTypes, function(tta){
	                			modal.annexaFormly.model.modal_body.transactionTypesAux.push(tta.transactionType.id);
	                		});
	                	}
						var labelPropField = [];
						if(modal.annexaFormly.model.modal_body.labelPropField){
							labelPropField = modal.annexaFormly.model.modal_body.labelPropField.split("--@--@--");
						}
						modal.annexaFormly.model.modal_body.columnsQuery = {
							identifier: modal.annexaFormly.model.modal_body.identifierField,
							labelProp: labelPropField,
							labelFieldsSeparator: modal.annexaFormly.model.modal_body.labelFieldsSeparator,
							parent:modal.annexaFormly.model.modal_body.parentField
						};
						var addedCF = [];
						if(modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.id){
							addedCF.push(modal.annexaFormly.model.modal_body.relatedCustomField.id);
						}
						var lvalues = [];
						if(modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id){
							if(data[2].data && data[2].data.length > 0){
								lvalues = JSOG.decode(data[2].data);
								var lvaluesActual = [];
								if(modal.annexaFormly.model.modal_body.columnsQuery.identifier && modal.annexaFormly.model.modal_body.columnsQuery.labelProp && modal.annexaFormly.model.modal_body.columnsQuery.labelProp.length > 0){
									_.forEach(lvalues, function(lv){
										if(lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier]){
											var label = '';
											_.forEach(modal.annexaFormly.model.modal_body.columnsQuery.labelProp, function(lp){
												label = label + ((label)?((modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator)?modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
											});
											if(modal.annexaFormly.model.modal_body.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && modal.annexaFormly.model.modal_body.columnsQuery.parent && lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]){
												lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], parentValue: {id:lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]}});
											}else{
												lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier] });
											}
										}
									});
								}
								modal.annexaFormly.model.modal_body.relatedCustomField.listValues = lvaluesActual;
								modal.annexaFormly.model.modal_body.relatedCustomField.originalListValues = lvalues;
							}
						}
        				modal.extra = {index: index, customFieldParent:cf}
	            		modal.annexaFormly.options = { watchAllExpressions: true };
	            		var cfl = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'customFieldLabel'");
	            		if(cfl && cfl.templateOptions){
	            			cfl.templateOptions.value = ((modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField[vm.languageColumn])?modal.annexaFormly.model.modal_body.relatedCustomField[vm.languageColumn]:'');
	            			cfl.templateOptions.editObject = function(){
	            				var queryOld = angular.copy(modal.annexaFormly.model.modal_body.relatedCustomField.query); 
	            				var originalListValuesOld = angular.copy(modal.annexaFormly.model.modal_body.relatedCustomField.originalListValues); 
	            				var listValuesOld = angular.copy(modal.annexaFormly.model.modal_body.relatedCustomField.listValues); 
	            				var saveEditCustomField = function(customFieldModified) {
	            					if(modal.annexaFormly.model.modal_body.columnsQuery){
		            					modal.annexaFormly.model.modal_body.columnsQuery.modifyCFFromModal = true;
		            				}
		            				var promises = [];
	                        		promises.push($http({method: 'GET',url: './api/custom_fields/is_disabled/'+cf.relatedCustomField.id}));
	                        		if(customFieldModified && customFieldModified.fromQuery && customFieldModified.query && customFieldModified.query.id  && (!queryOld || !queryOld.id || (queryOld && queryOld.id && customFieldModified.query.id !== queryOld.id))){
		                	            promises.push($http({method: 'PUT',url: './api/custom_fields/listValues', data: {
		                	            	customFieldId: cf.relatedCustomField.id,
		                	            	parentValueId: parentValue,
		                	            	additionalFilter: JSON.stringify(qp),
		                	            	objectParentId:vm.data.parent,
		                	            	objectParentType:vm.data.type,
		                	            	inConfig:true
		                	            }}));
		                	            promises.push($http({method: 'GET',url: './api/custom_fields/getColumnsQuery/'+cf.relatedCustomField.id}));
	                        		}
	                	            $q.all(promises).then(function(data){
		            					if(modal.annexaFormly.model.modal_body.columnsQuery){
			            					modal.annexaFormly.model.modal_body.columnsQuery.modifyCFFromModal = false;
			            				}
	                	            	modal.annexaFormly.model.modal_body.relatedCustomField = customFieldModified;
	            						modal.annexaFormly.model.modal_body.canEditCustomField = JSOG.decode(data[0].data);
	            						var lvalues = [];
	            						if(modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id){
	            							if(customFieldModified && customFieldModified.fromQuery && customFieldModified.query && customFieldModified.query.id  && (!queryOld || !queryOld.id || (queryOld && queryOld.id && customFieldModified.query.id !== queryOld.id))){
	            								if(data[1].data && data[1].data.length > 0){
	            									lvalues = JSOG.decode(data[1].data);
	            									var lvaluesActual = [];
	            									if(modal.annexaFormly.model.modal_body.columnsQuery.identifier && modal.annexaFormly.model.modal_body.columnsQuery.labelProp && modal.annexaFormly.model.modal_body.columnsQuery.labelProp.length > 0){
	            										_.forEach(lvalues, function(lv){
	            											if(lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier]){
	            												var label = '';
	            												_.forEach(modal.annexaFormly.model.modal_body.columnsQuery.labelProp, function(lp){listValuesOld
	            													label = label + ((label)?((modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator)?modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	            												});
	            												if(modal.annexaFormly.model.modal_body.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && modal.annexaFormly.model.modal_body.columnsQuery.parent && lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]){
	            													lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], parentValue: {id:lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]}});
	            												}else{
	            													lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier] });
	            												}
	            											}
	            										});
	            									}
	            									modal.annexaFormly.model.modal_body.relatedCustomField.listValues = lvaluesActual;
	            									modal.annexaFormly.model.modal_body.relatedCustomField.originalListValues = lvalues;
	            								}else{
	            									modal.annexaFormly.model.modal_body.relatedCustomField.listValues = [];
	            									modal.annexaFormly.model.modal_body.relatedCustomField.originalListValues = [];
	            								}
	            							}else{
	            								modal.annexaFormly.model.modal_body.relatedCustomField.listValues = listValuesOld;
            									modal.annexaFormly.model.modal_body.relatedCustomField.originalListValues = originalListValuesOld;
	            							}
	            						}
	            						var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValue'");
	            	            		if(cft && cft.data){
	            	            			cft.data.key = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.id)?modal.annexaFormly.model.modal_body.relatedCustomField.id:undefined);
	            	            			cft.data.frontendType = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.frontendType)?modal.annexaFormly.model.modal_body.relatedCustomField.frontendType:undefined);
	            	            			cft.data.backendType = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.backendType)?modal.annexaFormly.model.modal_body.relatedCustomField.backendType:undefined);
	            	            			cft.data.minLength = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.minLength)?modal.annexaFormly.model.modal_body.relatedCustomField.minLength:undefined);
	            	            			cft.data.maxLength = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.maxLength)?modal.annexaFormly.model.modal_body.relatedCustomField.maxLength:undefined);
	            	            			cft.data.decimalPositions = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.decimalPositions)?modal.annexaFormly.model.modal_body.relatedCustomField.decimalPositions:undefined);
	            	            			cft.data.model = modal.annexaFormly.model.modal_body.value;
	            	            			cft.data.cfDisabled = false;
	            	            			if(modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id){
	            	            				cft.data.optionLabelProp = "description";
	            	            			}else{
	            	            				cft.data.optionLabelProp = vm.languageColumn;
	            	            			}
	            	            			cft.data.label = $filter('translate')('global.literals.defaultValue');
	            	            			cft.data.options = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.listValues)?modal.annexaFormly.model.modal_body.relatedCustomField.listValues:[]);
	            	            		}
	            	            		if(customFieldModified && customFieldModified.fromQuery && customFieldModified.query && customFieldModified.query.id  && (!queryOld || !queryOld.id || (queryOld && queryOld.id && customFieldModified.query.id !== queryOld.id))){
	            	            			var columnsAux = ((data[2] && data[2].data)?JSOG.decode(data[2].data):[]);
	            	            			if(columns){
	            	            				columns.length = 0;
	            	            				if(columnsAux && columnsAux.length > 0){
	            	            					_.forEach(columnsAux, function(col){
	            	            						columns.push({id:col, description:col});
	            	            					});
	            	            				}
	            	            			}
	            	            			var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'columnsQuery'");
	            		            		if(cft && cft.fieldGroup){
	            		            			modal.annexaFormly.model.modal_body.columnsQuery = {};
	            		            			var cftIdent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'identifier'");
	            			            		if(cftIdent && cftIdent.templateOptions){
	            			            			cftIdent.templateOptions.options = columns;
	            			            		}
	            			            		var cftLP = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'labelProp'");
	            			            		if(cftLP && cftLP.templateOptions){
	            			            			cftLP.templateOptions.options = columns;
	            			            		}
	            			            		var cftparent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'parent'");
	            			            		if(cftparent && cftparent.templateOptions){
	            			            			cftparent.templateOptions.options = columns;
	            			            		}
	            		            		}
	            	            			var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParams'");
	            	            			modal.annexaFormly.model.modal_body.queryParams = {};
	            	            			cft.fieldGroup.length = 0;
	            		            		if(cft && modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id && modal.annexaFormly.model.modal_body.relatedCustomField.query.parameters && modal.annexaFormly.model.modal_body.relatedCustomField.query.parameters.length > 0 ){
	            			            		var parameters = $linq(modal.annexaFormly.model.modal_body.relatedCustomField.query.parameters).orderBy("x => x.viewOrder").toArray();
	            								_.forEach(parameters, function(param){
	            									if(param && param.queryParameter){
	            										var typesParam = [];
	            										typesParam.push({id:'##MANUAL##', description:"global.literals.manual"});
	            										typesParam.push({id:'##PARENT##', description:"global.literals.father"});
	            										_.forEach(vm.customFields, function(cf){
	            											typesParam.push({id:cf.relatedCustomField.templateTag, description:cf.relatedCustomField[vm.languageColumn]});
	            										});
	            										cft.fieldGroup.push({
	            	    									key: param.queryParameter.alias+"_Type_Param_Modal",
	            	    									type: 'annexaSelectRow',
	            	    									className: 'col-sm-6 m-b-xs',
	            	    									templateOptions: {
	            	    										optionsAttr: 'bs-options',
	            	    										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	            	    										label: param.queryParameter[vm.languageColumn]+" "+$filter("translate")("global.literals.type"),
	            	    										valueProp: 'id',
	            	    										labelProp: 'description',
	            	    										placeholder: '',
	            	    										options: typesParam,
	            	    										required: true
	            	    									},
	            	    									data: {
	            	    										row: true,
	            	    										informed: true,
	            	    										colClass: ' col-xs-12',
	            	    										labelClass: 'label-strong',
	            	    										clear: function($event,model,key, $select) {
	            	    											$event.stopPropagation();
	            	    											model[key] = undefined;
	            	    											if($select) {
	            	    												$select.selected = undefined;
	            	    												$select.search = undefined;
	            	    											}
	            	    										}
	            	    									}
	            	    								});
	            										fieldQueryCreated = QueryFactory.createQueryParameterField(param, false, false, 'col-sm-6');
	            										if(fieldQueryCreated){
	            											fieldQueryCreated.hideExpression = function ($viewValue, $modelValue, scope) {
	            												if(scope.model && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] === "##MANUAL##"){
	            													return false;
	            												}else{
	            				  	                                return true;
	            				                                }
	            					                      	}
	            										}
	            										cft.fieldGroup.push(fieldQueryCreated);
	            										modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias+"_Type_Param_Modal"] = "##MANUAL##";
	            									}
	            								});
	            		            		}
		            	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
		            	            		modal.annexaFormly.model.modal_body.relatedCustomFields = {};
		            	            		cft.fieldGroup.length = 0;
	            		            	}else if(customFieldModified && (!customFieldModified.fromQuery || (!customFieldModified.query && !customFieldModified.query.id))){
	            		            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParams'");
	            	            			modal.annexaFormly.model.modal_body.queryParams = {};
	            	            			cft.fieldGroup.length = 0;
	            	            			var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
		            	            		modal.annexaFormly.model.modal_body.relatedCustomFields = {};
		            	            		cft.fieldGroup.length = 0;
	            		            	}
	                	            });
	                        	}
	            				CustomFieldFactory.customFieldModal(modal.annexaFormly.model.modal_body.relatedCustomField.id, saveEditCustomField);
	            			} 
	            		}
	            		var rString = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'requiredString'");
	            		if(rString && rString.templateOptions){
	            			rString.templateOptions.options = vm.customFieldRequiredList;
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'customFieldType'");
	            		if(cft && cft.templateOptions){
	            			if($state && $state.current && $state.current.name && ($state.current.name == 'annexa.admin.guided_procedure.new_guided_procedure' || $state.current.name == 'annexa.admin.guided_procedure.edit_guided_procedure')){
	            				cft.templateOptions.options = vm.customFieldProcedureGuidedType;
	            			}else{
	            				cft.templateOptions.options = vm.customFieldProcedureType;
	            			}
	            		}
	            		if(vm.data.type == 'PROCEDURE'){
		            		var spel = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'spel'");
		    				if(spel){
		    					spel.templateOptions.label = 'global.literals.conditionDossierTransaction';
		    				}
	            			var spel = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'spelCalculated'");
		    				if(spel){
		    					spel.templateOptions.label = 'global.literals.conditionCalculatedDossierTransaction';
		    				}
	            		}
	            		var executeSpel = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpel'");
	    				if(executeSpel){
	    					executeSpel.templateOptions.executeFunction = function() {
	    						var params = [];
	    						var actualCF = {};
								if(vm.data.type === 'PROCEDURE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.procedureGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						}else if(vm.data.type === 'TASK_TYPE'){
	    							params.push({name:'task', type:{id:'Task',name:'Task'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.taskTypeGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						}else if(vm.data.type === 'DOCUMENT_TYPE'){
	    							params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.documentTypeGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						}else if(vm.data.type === 'TRANSACTION_TYPE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.transactionTypeGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
		    					}
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spel)?modal.annexaFormly.model.modal_body.spel:undefined), params, actualCF);	
	    		        	};
	    				}
	    				var executeSpelDossier = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpelDossier'");
	    				if(executeSpelDossier){
	    					executeSpelDossier.templateOptions.executeFunction = function() {
	    						var params = [];
	    						var actualCF = {};
								params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    						actualCF.procedureGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelDossier)?modal.annexaFormly.model.modal_body.spelDossier:undefined), params, actualCF);	
	    		        	};
	    				}
	    				var executeSpelCalculated = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpelCalculated'");
	    				if(executeSpelCalculated){
	    					executeSpelCalculated.templateOptions.executeFunction = function() {
	    						var params = [];
	    						var actualCF = {};
								if(vm.data.type === 'PROCEDURE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.procedureGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						}else if(vm.data.type === 'TASK_TYPE'){
	    							params.push({name:'task', type:{id:'Task',name:'Task'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.taskTypeGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						}else if(vm.data.type === 'DOCUMENT_TYPE'){
	    							params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.documentGTypeCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						}else if(vm.data.type === 'TRANSACTION_TYPE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							actualCF.transactionTypeGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
		    					}
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelCalculated)?modal.annexaFormly.model.modal_body.spelCalculated:undefined), params, actualCF);	
	    		        	};
	    				}
	    				var executeSpelCalculatedDossier = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpelCalculatedDossier'");
	    				if(executeSpelCalculatedDossier){
	    					executeSpelCalculatedDossier.templateOptions.executeFunction = function() {
	    						var params = [];
	    						var actualCF = {};
								params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    						actualCF.procedureGCF = ((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.id)?modal.annexaFormly.model.modal_body.id:undefined);
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelCalculatedDossier)?modal.annexaFormly.model.modal_body.spelCalculatedDossier:undefined), params, actualCF);	
	    		        	};
	    				}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValue'");
	            		if(cft && cft.data){
	            			cft.data.key = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.id)?modal.annexaFormly.model.modal_body.relatedCustomField.id:undefined);
	            			cft.data.frontendType = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.frontendType)?modal.annexaFormly.model.modal_body.relatedCustomField.frontendType:undefined);
	            			cft.data.backendType = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.backendType)?modal.annexaFormly.model.modal_body.relatedCustomField.backendType:undefined);
	            			cft.data.minLength = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.minLength)?modal.annexaFormly.model.modal_body.relatedCustomField.minLength:undefined);
	            			cft.data.maxLength = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.maxLength)?modal.annexaFormly.model.modal_body.relatedCustomField.maxLength:undefined);
	            			cft.data.decimalPositions = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.decimalPositions)?modal.annexaFormly.model.modal_body.relatedCustomField.decimalPositions:undefined);
	            			cft.data.model = modal.annexaFormly.model.modal_body.value;
	            			cft.data.cfDisabled = false;
	            			if(modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id){
	            				cft.data.optionLabelProp = "description";
	            			}else{
	            				cft.data.optionLabelProp = vm.languageColumn;
	            			}
	            			cft.data.label = $filter('translate')('global.literals.defaultValue');
	            			cft.data.options = ((modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.listValues)?modal.annexaFormly.model.modal_body.relatedCustomField.listValues:[]);
	            		}
	            		var columnsAux = ((data[3] && data[3].data)?JSOG.decode(data[3].data):[]);
	            		var columns = [];
	            		if(columnsAux && columnsAux.length > 0){
	            			_.forEach(columnsAux, function(col){
	            				columns.push({id:col, description:col});
	            			});
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParams'");
	            		var paraAux = ((modal.annexaFormly.model.modal_body.queryParams)?angular.copy(modal.annexaFormly.model.modal_body.queryParams):[]);
	            		modal.annexaFormly.model.modal_body.queryParams = {};
	            		if(cft && modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id && modal.annexaFormly.model.modal_body.relatedCustomField.query.parameters && modal.annexaFormly.model.modal_body.relatedCustomField.query.parameters.length > 0 ){
		            		var parameters = $linq(modal.annexaFormly.model.modal_body.relatedCustomField.query.parameters).orderBy("x => x.viewOrder").toArray();
							_.forEach(parameters, function(param){
								if(param && param.queryParameter){
									var typesParam = [];
									typesParam.push({id:'##MANUAL##', description:"global.literals.manual"});
									typesParam.push({id:'##PARENT##', description:"global.literals.father"});
									var addGroups = function(cf, id, names){
										var types = [];
										if(cf && cf.groups){
											var exist = false;
											var existInGroup = $linq(cf.groups).firstOrDefault(undefined, "x => x.id == "+id);
											_.forEach(cf.groups, function(gr){
												if(existInGroup){
													exist = true;
													if(!_.contains(names, gr.relatedCustomField.templateTag)){
														types.push({id:gr.relatedCustomField.templateTag, description:gr.relatedCustomField[vm.languageColumn]});
														names.push(gr.relatedCustomField.templateTag);
													}
												}else{
													if(!exist){
														typesAux = addGroups(gr, id, names);
														if(typesAux != null && typesAux.length > 0){
															exist = true;
															if(!_.contains(names, gr.relatedCustomField.templateTag)){
																types.push({id:gr.relatedCustomField.templateTag, description:gr.relatedCustomField[vm.languageColumn]});
																names.push(gr.relatedCustomField.templateTag);
															}
															_.forEach(typesAux, function(type){
																if(!_.contains(names, gr.relatedCustomField.templateTag)){
																	types.push({id:"r."+type.id, description:gr.relatedCustomField.templateTag+"."+type.description});
																	names.push(gr.relatedCustomField.templateTag);
																}
															});
														}
													}
												}
											});
										}
										return types;
									}
									if(vm.data && vm.data.procedureCustomFields){
										var typesNames = [];
										_.forEach(vm.data.procedureCustomFields, function(cf){
											typesParam.push({id:cf.customField.templateTag, description:cf.customField[vm.languageColumn]});
											typesNames.push(cf.customField.templateTag);
											if(cf.customField.frontendType === 'CF_GROUP'){
												var tps = addGroups(cf,modal.annexaFormly.model.modal_body.id, typesNames);
												_.forEach(tps, function(tp){
													typesParam.push(tp);
												});
											}
										});
									}
									cft.fieldGroup.push({
    									key: param.queryParameter.alias+"_Type_Param_Modal",
    									type: 'annexaSelectRow',
    									className: 'col-sm-6 m-b-xs',
    									templateOptions: {
    										optionsAttr: 'bs-options',
    										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
    										label: param.queryParameter[vm.languageColumn]+" "+$filter("translate")("global.literals.type"),
    										valueProp: 'id',
    										labelProp: 'description',
    										placeholder: '',
    										options: typesParam,
    										required: true
    									},
    									data: {
    										row: true,
    										informed: true,
    										colClass: ' col-xs-12',
    										labelClass: 'label-strong',
    										clear: function($event,model,key, $select) {
    											$event.stopPropagation();
    											model[key] = undefined;
    											if($select) {
    												$select.selected = undefined;
    												$select.search = undefined;
    											}
    										}
    									}
    								});
									fieldQueryCreated = QueryFactory.createQueryParameterField(param, false, false, 'col-sm-6');
									if(fieldQueryCreated){
										fieldQueryCreated.hideExpression = function ($viewValue, $modelValue, scope) {
											if(scope.model && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] === "##MANUAL##"){
												return false;
											}else{
			  	                                return true;
			                                }
				                      	}
									}
									cft.fieldGroup.push(fieldQueryCreated);
									var qpaux = ((paraAux)?$linq(paraAux).firstOrDefault(undefined, "x => x.parameter == '"+param.queryParameter.alias+"'"):undefined);
									modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias+"_Type_Param_Modal"] = ((qpaux && qpaux.parameterType)?qpaux.parameterType:"##MANUAL##");
									if(param.queryParameter && param.queryParameter.queryParameterType  == 'DATE' && qpaux && qpaux.parameterValue){
										modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = qpaux.parameterValue;
					                }else  if (param.queryParameter && (param.queryParameter.queryParameterType == 'INTEGER' || param.queryParameter.queryParameterType == 'CURRENT_USER') && qpaux && qpaux.parameterValue) {
					                	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = parseInt(qpaux.parameterValue);
					                } else if (param.queryParameter && param.queryParameter.queryParameterType == 'DOUBLE'  && qpaux && qpaux.parameterValue) {
				                        var valueLiteral_parts = (qpaux.parameterValue).split('.');
				                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
				                            if(valueLiteral_parts && valueLiteral_parts.length > 1 && valueLiteral_parts[1]){
				                            	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
				                            }else{
				                            	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
				                            }
				                        }
					                } else if(param.queryParameter && (param.queryParameter.queryParameterType == 'BOOLEAN' || param.queryParameter.queryParameterType == 'BOOLEAN_NULL') && qpaux && qpaux.parameterValue){
					                	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = qpaux.parameterValue;
					                }
								}
							});
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'columnsQuery'");
	            		if(cft && cft.fieldGroup){
	            			var cftIdent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'identifier'");
		            		if(cftIdent && cftIdent.templateOptions){
		            			cftIdent.templateOptions.options = columns;
		            		}
		            		var cftLP = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'labelProp'");
		            		if(cftLP && cftLP.templateOptions){
		            			cftLP.templateOptions.options = columns;
		            		}
		            		var cftparent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'parent'");
		            		if(cftparent && cftparent.templateOptions){
		            			cftparent.templateOptions.options = columns;
		            		}
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addRelatedCustomField'");
	            		if(cft && cft.templateOptions){
	            			cft.templateOptions.executeFunction = function(){
	            				var saveCustomFieldRelatedFunction = function(){
	            					if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.annexaFormly.model.modal_body.relatedCustomField && this.annexaFormly.model.modal_body.relatedQueryColumn){
	            						var cfAux = $linq(vm.data.procedureCustomFields).select("x => x.customField").firstOrDefault(undefined, "x => x.id =="+this.annexaFormly.model.modal_body.relatedCustomField);
	            						if(cfAux && cfAux.templateTag){
	            							var cfRt = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
	            							if(cfRt && cfRt.fieldGroup){
	            								addedCF.push(cfAux.id);
	            								cfRt.fieldGroup.push({
	            									key: cfAux.templateTag,
	            									type: 'annexaSelectRow',
	            									className: 'col-sm-4 m-b-xs',
	            									templateOptions: {
	            										optionsAttr: 'bs-options',
	            										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	            										label: cfAux[vm.languageColumn],
	            										valueProp: 'id',
	            										labelProp: 'description',
	            										placeholder: '',
	            										options: columns,
	            										required: true
	            									},
	            									data: {
	            										row: true,
	            										informed: true,
	            										colClass: ' col-xs-12',
	            										labelClass: 'label-strong',
	            										clear: function($event,model,key, $select) {
	            											$event.stopPropagation();
	            											model[key] = undefined;
	            											if($select) {
	            												$select.selected = undefined;
	            												$select.search = undefined;
	            											}
	            										}
	            									}
	            								});
	            								if(!modal.annexaFormly.model.modal_body.relatedCustomFields){
	            									modal.annexaFormly.model.modal_body.relatedCustomFields = {};
	            								}
	            								modal.annexaFormly.model.modal_body.relatedCustomFields[cfAux.templateTag] = this.annexaFormly.model.modal_body.relatedQueryColumn;
	            							}
	            						} 
	            					}
	            					this.close();
	            				}
	            				var modalRelated = angular.copy(CommonAdminModals.customFieldsRelated);
	            				modalRelated.alerts = [];
	            				modalRelated.annexaFormly.model = {modal_body: {}};
	    	            		var cfRt = $linq(modalRelated.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomField'");
	    	            		if(cfRt && cfRt.templateOptions){
	    	            			cfRt.templateOptions.options = angular.copy($linq(vm.data.procedureCustomFields).except(addedCF, "(x, y) => y.customField && y.customField.id == x").select("x => x.customField").toArray());
	    	            		}
	    	            		var cfRt = $linq(modalRelated.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedQueryColumn'");
	    	            		if(cfRt && cfRt.templateOptions){
	    	            			cfRt.templateOptions.options = angular.copy(columns);
	    	            		}
	    						AnnexaFormlyFactory.showModal('modalCustomFieldParentRelated',modalRelated,saveCustomFieldRelatedFunction,false);
	            			};
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
	            		var paraAux = ((modal.annexaFormly.model.modal_body.relatedCustomFields)?angular.copy(modal.annexaFormly.model.modal_body.relatedCustomFields):[]);
	            		modal.annexaFormly.model.modal_body.relatedCustomFields = {};
	            		if(cft && modal.annexaFormly.model.modal_body.relatedCustomField && modal.annexaFormly.model.modal_body.relatedCustomField.fromQuery && modal.annexaFormly.model.modal_body.relatedCustomField.query && modal.annexaFormly.model.modal_body.relatedCustomField.query.id && paraAux && paraAux.length > 0 ){
							_.forEach(paraAux, function(param){
								if(param && param.customFieldTemplateTag){
									var cfAux = $linq(vm.data.procedureCustomFields).select("x => x.customField").firstOrDefault(undefined, "x => x.templateTag == '"+param.customFieldTemplateTag+"'");
            						if(cfAux && cfAux.templateTag){
            							if(cft && cft.fieldGroup){
            								addedCF.push(cfAux.id);
            								cft.fieldGroup.push({
            									key: cfAux.templateTag,
            									type: 'annexaSelectRow',
            									className: 'col-sm-4 m-b-xs',
            									templateOptions: {
            										optionsAttr: 'bs-options',
            										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            										label: cfAux[vm.languageColumn],
            										valueProp: 'id',
            										labelProp: 'description',
            										placeholder: '',
            										options: columns,
            										required: true
            									},
            									data: {
            										row: true,
            										informed: true,
            										colClass: ' col-xs-12',
            										labelClass: 'label-strong',
            										clear: function($event,model,key, $select) {
            											$event.stopPropagation();
            											model[key] = undefined;
            											if($select) {
            												$select.selected = undefined;
            												$select.search = undefined;
            											}
            										}
            									}
            								});
            							}
            						}
									var cfval = ((paraAux)?$linq(paraAux).firstOrDefault(undefined, "x => x.customFieldTemplateTag == '"+param.customFieldTemplateTag+"'"):undefined);
									modal.annexaFormly.model.modal_body.relatedCustomFields[param.customFieldTemplateTag] = ((cfval)?cfval.customFieldValue:undefined);
								}
							});
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValueReloadButton'");
	            		if(cft && cft.templateOptions){
	            			cft.templateOptions.objectParentId = vm.parentId;
	            			cft.templateOptions.objectParentType = vm.type;
	            			cft.templateOptions.inConfig = true;
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'configGroup'");
	            		if(cft && cft.data){
	            			cft.data.objectGroups = modal.annexaFormly.model.modal_body.groups;
	            			cft.data.groups = modal.annexaFormly.model.modal_body.relatedCustomField.groups;
	            			cft.data.type = vm.data.type;
	            			cft.data.objectParent = cf.id;
	            			cft.data.parent = vm.data.parent;
	            			cft.data.showCustomFieldType = vm.data.showCustomFieldType;
	            			cft.data.procedureCustomFields = vm.data.procedureCustomFields;
	            		}
	            		AnnexaFormlyFactory.showModal('modalCustomFieldsGroupConfig'+new Date().getTime(),modal,saveParentCustomFieldFunction,false);
	            	}).catch(function(error){
	            	})
            	}
            }

        	this.$onInit = function () {
        		if(vm.data){
            		if(!vm.data.groups){
            			vm.data.groups = [];
            		}
            		if(!vm.data.objectGroups){
            			vm.data.objectGroups = [];
            		}
            		if(vm.data.type == 'PROCEDURE'){
                         vm.customFieldRequiredList.push({ id: 'REQUIRED_TO_END', description: 'global.literals.REQUIRED_TO_END' });
            		}
            	}else{
            		vm.data = {groups:[], objectGroups:[]};
            	}
        		
            }
        }],
        bindings: {
            data: '=?'
        }
    });