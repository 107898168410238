angular
    .module('annexaApp')
    .component('annexaCustomFieldsGroup', {
        templateUrl: './components/common/annexa-custom-fields-group/annexa-custom-fields-group.html',
        controller: ['$http', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaEntityFactory', 'Language', '$filter', 'HelperService', 'CommonService', 'GlobalDataFactory', 
        function($http, $rootScope, globalModals, AnnexaFormlyFactory, AnnexaEntityFactory, Language, $filter, HelperService, CommonService, GlobalDataFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.allCustomFields = [];
            vm.addedCustomField = undefined;
            
            vm.removeCustomField = function(index){
            	vm.data.customFieldList.splice(index, 1);
            }
            
            vm.sort = function(item, partFrom, partTo, indexFrom, indexTo){
                if(partTo){
                    _.forEach(partTo, function(value, key){
                        value.viewOrder = key + 1;
                    });
                }
            }

            vm.canDelete = function(item){
        		if(!vm.data.isDisabled || (item && !item.id)){
        			return true;
        		}else{
        			return false;
        		}
        	}
            
            vm.addCustomField = function(model){
            	var index = 0;
            	if(vm.data && vm.data.customFieldList && vm.data.customFieldList.length > 0){
            		index = $linq(vm.data.customFieldList).max("x => x.viewOrder");
            	}
            	index = index+1;
            	vm.data.customFieldList.push({viewOrder: index, relatedCustomField:model});
            	vm.addedCustomField = undefined;
            }
            this.$onInit = function () {
            	if(vm.data){
            		if(!vm.data.customFieldList){
            			vm.data.customFieldList = [];
            		}
            	}else{
            		vm.data = {customFieldList:[]};
            	}
            	if(vm.data.customFieldList && vm.data.customFieldList.length > 1){
            		vm.data.customFieldList = $linq(vm.data.customFieldList).orderBy("x => x.viewOrder", HelperService.caseInsensitiveOrNullComparer).toArray();
            	}
            	var cfsOrders = $linq(GlobalDataFactory.customFields).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray(); 
                 _.forEach(cfsOrders, function(item) {
                     vm.allCustomFields.push({id: item.id, language1: item.language1, language2: item.language2, language3: item.language3});
                 });
            }
        }],
        bindings: {
            data: '=?'
        }
    });
