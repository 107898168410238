angular
    .module('annexaApp')
    .component('annexaQueryParameterComponent', {
        templateUrl: './components/common/annexa-query-parameter-component/annexa-query-parameter-component.html',
        controller: ['$http', '$rootScope', '$filter', function($http, $rootScope, $filter) {
            var vm = this;
            
            vm.loggedUser = $rootScope.LoggedUser;
            vm.isEditable = true;

            //region Datepicker

            vm.datepicker = {};

            vm.datepicker.opened = false;

            vm.datepicker.open = function ($event) {
                vm.datepicker.opened = !vm.datepicker.opened;
            };

            vm.datepickerOptions = {
                format: 'dd/MM/yyyy',
                initDate: new Date(),
                showWeeks: false,
                startingDay: 1
            }
            
			vm.onChangeDataPickerMask = function(key, type) {
            	vm.actualModel = $filter('date')(vm.actualModelAux, 'dd/MM/yyyy');
            	vm.optionOnSelectAux(key, type);
        	}

            //endregion

            //region Number
            
            

            vm.step = 0;
            vm.actualModel = '';
            vm.actualModelAux = '';

            vm.inputTextType = 'text';

            if(!vm.minLength) {
                vm.minLength = 0;
            }

            if(!vm.maxLength) {
                vm.maxLength = 9999;
            }

            if(!vm.decimalPositions) {
                vm.decimalPositions = 0;
            }

            if (vm.queryParameterType == 'INTEGER') {
                vm.inputTextType = 'number';
                vm.step = 1;
            } else if (vm.queryParameterType == 'DOUBLE') {
                vm.inputTextType = 'number';
                vm.step = '0.' + Array(vm.decimalPositions).join('0') + '1';
            } else if (vm.queryParameterType == 'BOOLEAN') {
                vm.options = [
                    {id:'', description:'global.literals.empty'},
                    {id:'1', description:'global.literals.yes'},
                    {id:'0', description:'global.literals.no'}
                ];
            } else if (vm.queryParameterType == 'BOOLEAN_NULL') {
            	vm.options = [
                    {id:'', description:'global.literals.empty'},
                    {id:'1', description:'global.literals.yes'},
                    {id:'0', description:'global.literals.no'}
                ];
            } else if (vm.queryParameterType == 'CURRENT_USER') {
            	vm.inputTextType = 'number';
            	vm.step = 1;
            }

            //endregion

            //region Select
            vm.clear = function($event,model$select) {
                $event.stopPropagation();
                model = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            };

            //endregion

            this.$onInit = function () {
                if(!vm.showFunctionParam){
                    vm.showFunction = function(){
                        return true;
                    }
                }else{
                    vm.showFunction = vm.showFunctionParam;
                }
                if(typeof vm.options === 'string') {
                    var url = vm.options;
                    vm.options = [];

                    $http({
                        url: url,
                        method: 'GET'
                    }).then(function(data) {
                        var decodedData = JSOG.decode(data.data);
                        vm.options = angular.fromJson(decodedData);
                    }).catch(function (error) {
                        vm.options = [];
                    });
                }
                if(vm.queryParameterType  == 'DATE' && vm.model){
                	vm.actualModel = vm.model;
                }else  if (vm.queryParameterType == 'INTEGER') {
                    vm.actualModel = parseInt(vm.model);
                } else if (vm.queryParameterType == 'DOUBLE') {
                    if(vm.model){
                        var valueLiteral_parts = (vm.model).split('.');
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(vm.decimalPositions){
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(vm.decimalPositions));
                                }else{
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(vm.decimalPositions){
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(vm.decimalPositions));
                                }else {
                                    vm.actualModel =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                    }
                } else if(vm.queryParameterType == 'BOOLEAN' || vm.queryParameterType == 'BOOLEAN_NULL'){
                    vm.actualModel = vm.model;
                } else if(vm.queryParameterType == 'CURRENT_USER'){
                	vm.actualModel = parseInt(vm.loggedUser.id);
                	vm.isEditable = false;
                }
            };

            this.$onChanges = function (changes) {
                if(changes.options) {
                    if(typeof vm.options === 'string') {
                        var url = vm.options;
                        vm.options = [];

                        $http({
                            url: url,
                            method: 'GET'
                        }).then(function(data) {
                            var decodedData = JSOG.decode(data.data);
                            vm.options = angular.fromJson(decodedData);
                        }).catch(function (error) {
                            vm.options = [];
                        });
                    }
                }
            };

            vm.optionOnSelectAux = function(key, type){
                if(type){
                    if(type == 'DATE'){
                        if(vm.actualModel) {
                            vm.model = vm.actualModel;
                        }else{
                            vm.model = '';
                        }
                        vm.optionOnSelect(key, vm.model);

                    }else if(type == 'number' || type == 'SELECT'){
                        if(vm.actualModel){
                            vm.model = vm.actualModel;
                        }else{
                            vm.model = '';
                        }
                        vm.optionOnSelect(key, vm.model);
                    }
                }
            };
        }],
        bindings: {
            key: '@',
            queryParameterType: '@',
            label: '@',
            model: '=?',
            minLength: '=',
            maxLength: '=',
            optionLabelProp: '@',
            optionValueProp: '@',
            options: '<',
            optionOnSelect: '=',
            showFunctionParam: '='
        }
    });